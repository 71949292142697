import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"


const About = ({ data, location }) => {


  const message = `I am a software developer with a passion for building cool projects.`


  return (
    <Layout location={location}>
      <Seo title="About Me"
        description={message}
      />

      <main className="p-3">

        <section className="flex flex-col md:flex-row p-1">

          <div className="w-full md:w-1/2 p-3">
            <h1 className="dark:text-sky-50 text-center capitalize mb-7 font-bold text-blue-900 text-4xl">
              About Me
            </h1>

            <p className="text-slate-600 dark:text-sky-200 font-serif">
              Born and raised in the city filled with Jakaranda trees - City of Tshwane - I have always been <span className="font-semibold underline dark:decoration-blue-300 decoration-sky-300 underline-offset-4">fascinated by technology</span>. I think <span className="font-semibold underline dark:decoration-blue-300 decoration-sky-300 underline-offset-4">it comes natural</span> when you grew up in an era of so much technological advancement. My <span className="font-semibold underline dark:decoration-blue-300 decoration-sky-300 underline-offset-4">curiosity and passion for technology</span> has led me to pursue a career in software development.
            </p>
          </div>
          <div className="w-full md:w-1/2">
            <StaticImage

              className="rounded-md mx-auto"
              height={209}
              layout="fixed"
              // layout="s"
              formats={["auto", "png"]}
              src="../images/me/preschool.jpg"

              quality={95}
              alt="Djembeleza in preschool"
            />
          </div>


        </section>

        <section className="flex flex-col-reverse md:flex-row p-1">

          <div className="w-full md:w-1/2">
            <StaticImage
              layout="fixed"
              height={275}
              className="mx-auto rounded-md"
              formats={["auto", "png"]}
              src="../images/me/Polish_20220613_112017004.png"

              quality={95}
              alt="Djembeleza professional photo"
            />
          </div>
          <div className="w-full md:w-1/2 p-3">
            <h2 className="dark:text-sky-50 text-center capitalize mb-7 font-bold text-blue-900 text-4xl">
              My Hobbies
            </h2>

            <p className="text-slate-600 dark:text-sky-200 font-serif">
              I enjoy listening to music, I used to record in my spare time but now I mostly work with artists. I enjoy reading books, currently I am reading The Psychology of Money by Morgan Housel. Mostly I am coding and discovering cool applications in the Web3.0 space.
            </p>
          </div>


        </section>

        <section className="flex flex-col md:flex-row p-1">


          <div className="w-full md:w-1/2 p-3">
            <h2 className="dark:text-sky-50 text-center capitalize mb-7 font-bold text-blue-900 text-4xl">
              My Skills
            </h2>

            <h3>Technical Skills</h3>
            <ul className="text-slate-600 dark:text-sky-200 font-serif">
              <li>HTML5</li>
              <li>CSS3</li>
              <li>Python</li>
              <li>JavaScript</li>
              <li>React.js</li>
              <li>React Native</li>
            </ul>
          </div>

          <div className="w-full md:w-1/2">
          </div>


        </section>

      </main>
    </Layout>
  )
}

export default About

